import React from 'react';

const Gen1Tab = () => {
  return (
    <div className="landing">
      <div className="gen text-center">
        <p className="m-2 gen-text text-13 mt-2">
          For AWS Community Builders
          <a href="https://wanclouds.net/aws/communitybuilders">
            <span className="text-primary"> Click here </span>
          </a>
          for details.
        </p>
      </div>
    </div>
  );
};

export default Gen1Tab;
